import $ from 'jquery'
import Vue from 'vue'
import RU from './views/ru.vue'
import EN from './views/en.vue'
import VueI18n from 'vue-i18n'
import VueMeta from 'vue-meta'
import VImg from 'v-img'
import Cookies from 'js-cookie'

import HM from './hm.js'
import router from './router'

global.HM = HM
global.$ = global.jQuery = $
global.Cookies = Cookies

Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(VueMeta)
Vue.use(VImg)

Vue.component('v-icon', require('vue-awesome/components/Icon').default)

document.addEventListener('DOMContentLoaded', function () {
  if (HM.is_mobile) {
    document.body.classList.add('mobile')
  } else {
    document.body.classList.remove('mobile')
  }
})

Vue.use({
  install: function (VueConstructor, _) { VueConstructor.prototype.HM = HM }
})

new Vue({
  router,
  render: h => h(HM.is_russian ? RU : EN),
  i18n: new VueI18n({
    locale: HM.locale,
    silentTranslationWarn: true
  })
}).$mount('#promo')

window.snapSaveState = () => {
  document.querySelector('#promo').setAttribute('data-server-rendered', 'true')
}

navigator.serviceWorker && navigator.serviceWorker.getRegistrations().then(function (registrations) {
  for (let registration of registrations) {
    registration.unregister()
  }
})

try {
  let queryParams = Object.fromEntries(Array.from(new URLSearchParams(location.search).entries()))

  if (queryParams.friend_id) {
    Cookies.set('friend_id', queryParams.friend_id, { expires: 60, domain: location.hostname })
    $.getJSON(HM.api_url + 'visit_friends_page?friend_id=' + queryParams.friend_id)
  }
} catch (error) { }
