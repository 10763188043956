































distanceInWordsToNow = require('date-fns/distance_in_words_to_now')
ru = require('date-fns/locale/ru')
en = require('date-fns/locale/en')
distance_in_words_to_now = (date) ->
  locale = if global.HM.is_russian then ru else en
  result = distanceInWordsToNow(date, locale: locale)
  result + (if global.HM.is_russian then ' назад' else ' ago')

module.exports =
  name: 'hm_footer'
  data: ->
    current_year: new Date().getFullYear()
