let HM = {}

HM.is_touch = !!navigator.userAgent.match(/Mobi/)
HM.is_mobile = screen.width <= 700 || (window.matchMedia && window.matchMedia('only screen and (max-width: 700px)').matches)

HM.env = process.env.NODE_ENV || 'development'
HM.is_development = HM.env === 'development'
HM.is_production = HM.env === 'production'

const host = location.hostname

HM.locale = 'en'
if (host.includes('ru.test') || host.includes('hmbee') || location.pathname.includes('/ru')) {
  HM.locale = 'ru'
}
HM.is_english = HM.locale == 'en'
HM.is_russian = !HM.is_english
HM.is_demo = host.includes('demo.')

if (HM.is_production) {
  if (HM.is_russian) {
    HM.promo_site_url = 'https://hmbee.ru'
    HM.app_url = 'https://app.hmbee.ru'
    HM.demo_url = 'https://demo.hmbee.ru'
  } else {
    HM.promo_site_url = 'https://honeymoney.io'
    HM.app_url = 'https://app.honeymoney.io'
    HM.demo_url = 'https://demo.honeymoney.io'
  }
} else {
  if (HM.is_russian) {
    HM.promo_site_url = 'http://ru.test:8090'
    HM.app_url = 'http://app.ru.test:8080'
    HM.demo_url = 'http://demo.ru.test:8080'
  } else {
    HM.promo_site_url = 'http://en.test:8090'
    HM.app_url = 'http://app.en.test:8080'
    HM.demo_url = 'http://demo.en.test:8080'
  }
}

HM.api_url = process.env.NODE_ENV == 'production' ? `${HM.app_url}/api2/` : 'http://192.168.0.13:3000/api/'

export default HM
