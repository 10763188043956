import Vue from 'vue'
import Router from 'vue-router'
import HM from './hm.js'

Vue.use(Router)

let routes = []

function loadView (locale, view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${locale}/${view}.vue`)
}

if (HM.is_english) {
  routes = [
    {
      path: '/',
      name: 'home',
      component: loadView('en', 'index')
    },
    {
      path: '/en/',
      name: 'home_en',
      component: loadView('en', 'index')
    },
    {
      path: '/en/why',
      component: loadView('en', 'why')
    },
    {
      path: '/en/compare',
      component: loadView('en', 'compare')
    },
    {
      path: '/en/story',
      component: loadView('en', 'story')
    },
    {
      path: '/en/testimonials',
      component: loadView('en', 'testimonials')
    },
    {
      path: '/en/affiliates',
      component: loadView('en', 'affiliates')
    },
    {
      path: '/en/terms_of_use',
      component: loadView('en', 'terms_of_use')
    }
  ]
}

if (HM.is_russian) {
  routes = [
    {
      path: '/',
      name: 'home',
      component: loadView('ru', 'index')
    },
    {
      path: '/ru/',
      name: 'home_ru',
      component: loadView('ru', 'index')
    },
    {
      path: '/ru/about',
      component: loadView('ru', 'about')
    },
    {
      path: '/ru/testimonials',
      component: loadView('ru', 'testimonials')
    },
    {
      path: '/ru/pricing',
      component: loadView('ru', 'pricing')
    },
    {
      path: '/ru/affiliates',
      component: loadView('ru', 'affiliates')
    },
    {
      path: '/ru/spasibo',
      component: loadView('ru', 'spasibo')
    },
    {
      path: '/ru/ee',
      name: 'ee',
      component: loadView('ru', 'encyclopedia/_ee'),
      children: (function () {
        return [
          'auto', 'byt', 'deti', 'eda', 'krasota', 'odejda',
          'puteshestviya', 'razvlecheniya', 'remont', 'uslugi', 'zdorovie'
        ].map((slug) => {
          return {
            path: slug,
            name: `ee_${slug}`,
            // component: require(`./views/en/docs/${slug}.vue`).default
            // component: () => import(`./views/en/docs/${slug}.vue`)
            component: loadView('ru', `encyclopedia/${slug}`)
          }
        })
      })()
    }
  ]
}

let locales = ['en', 'ru']
let docs = {
  en: [
    'quick_start', 'reserves', 'mobile', 'login_problems', 'shortcuts', 'credit_cards', 'debts',
    'saving_for_taxes', 'faster'
  ],
  ru: ['quick_start', 'reserves', 'mobile', 'login_problems', 'credit_cards', 'debts',
    'saving_for_taxes', 'faster', 'comeback', 'why_manual']
}

locales.forEach(function (locale) {
  routes.push({
    path: `/${locale}/docs`,
    name: `${locale}_docs_index`,
    component: loadView('en', 'docs'),
    children: (function () {
      return docs[locale].map((slug) => {
        return {
          path: slug,
          name: `${locale}_docs_${slug}`,
          component: loadView(locale, `docs/${slug}`)
        }
      })
    })()
  })
})

routes.push({ path: '*', component: require('@/views/404.vue').default })

// VueI18n = require('vue-i18n').default
// Vue.use(VueI18n)
// new Vue
// el: '#promo'
// router: new VueRouter
// routes: routes
// mode: 'history'
// linkExactActiveClass: 'is-active'
// linkActiveClass: 'is-active'
// scrollBehavior: (to, from, savedPosition) ->
//       if savedPosition
//         return savedPosition
// else
//   return { x: 0, y: 0 }
// i18n: new VueI18n
// locale: HM.locale
// silentTranslationWarn: true
// render: (h) -> h(require("./#{HM.locale}/layouts/promo.vue").default)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  linkExactActiveClass: 'is-active',
  linkActiveClass: 'is-active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
