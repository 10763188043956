












module.exports =
  name: 'error_404'
  metaInfo:
    title: '404 Not Found - (ノ°Д°）ノ︵ ┻━┻'
